.item-management {
  &__header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-tabs-nav {
      margin: 0 0 12px 0 !important;
    }
  }

  &__range-date {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    color: #514b4d;
  }

  &__button-copy {
    height: 100%;
    display: flex;
    gap: 8px;
    padding: 0 !important;
    background: transparent;
    align-items: center;
  }

  .ant-tabs-tab {
    width: calc(calc(100% - 5px) / 8);
    height: 40px;
    padding: 9px 0;
    margin: 0 !important;

    .ant-tabs-tab-btn {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #7e7e7e;
      width: 100%;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: #231f20 !important;
        .item-management__title-tabs {
          span {
            color: #231f20 !important;
          }
        }

        svg {
          path {
            fill: #231f20 !important;
          }
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #231f20 !important;
    height: 3px !important;
    border-radius: 2px;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid #9d9d9d;
    width: 100%;
    justify-content: space-between;
  }

  // .ant-tabs-tab-active {
  //   border-bottom: 2px solid black !important;
  // }

  &__title {
    &-name {
      margin-left: 8px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-wrap: break-word;
      line-height: 2;
    }

    &-number {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: auto;
      color: #514b4d;
      margin-top: 0;
      margin-right: 8px;
    }

    &-tabs {
      width: 100%;
      display: flex;
      margin: 4px !important;
      height: 32px;
      line-height: 32px;
      &:hover {
        background-color: #f2f2f2;
        border-radius: 4px;
      }
    }
  }

  &__support {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 32px;
    margin-bottom: 32px;
  }

  .border-avatar {
    border-radius: 100px;
    border: 2px solid rgba(28, 134, 237, 0.32);
  }

  .switch-true {
    color: #514b4d;
  }

  .switch-false {
    color: #adadad;
  }

  .color-514B4D {
    color: #514b4d;
  }

  .bg-514B4D {
    background-color: #514b4d;
  }

  &__search-input {
    .ant-input {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #514b4d !important;

      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #adadad !important;
      }
    }
  }

  &__table {
    .ant-table-container {
      border: 1px solid #dcdcdc;
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
      //height: 677px;
      .ant-table-header {
        border-radius: 0 !important;
      }

      .ant-table-thead {
        .ant-table-cell {
          border-start-start-radius: 0 !important;
          border-start-end-radius: 0 !important;
          box-shadow: unset !important;

          &:before {
            content: unset !important;
            width: 0 !important;
            height: 0 !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          border-bottom: 1px solid #dcdcdc;
        }
      }
    }

    &-pagination {
      &-container {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        background: #f2f2f2;
        border-left: 1px solid #dcdcdc;
        border-right: 1px solid #dcdcdc;
        border-bottom: 1px solid #dcdcdc;

        .ant-pagination {
          .ant-pagination-item {
            background: #ffffff;
            border: 1px solid #514b4d;
            width: 32px;
            height: 32px;
            border-radius: 2px;
            display: inline-block;

            a {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #514b4d;
            }

            &-active {
              background: #514b4d;

              a {
                color: #ffffff;
              }
            }

            &-link {
              background: #ffffff;
              border: 1px solid #eef0f4;
              width: 32px;
              height: 32px;
              border-radius: 2px;
            }
          }

          &-jump {
            &-next,
            &-prev {
              a {
                display: inline-block;
                //background: unset !important;
                .ant-pagination-item-ellipsis {
                  color: #514b4d !important;
                }
              }
            }
          }

          .ant-pagination-options {
            .ant-select {
              .ant-select-arrow {
                font-size: 17px;
                color: #514b4d;
              }

              &-selector {
                width: 88px;
                height: 32px;
                border: 1px solid #adadad;
                border-radius: 2px;
                padding: 5px 10px;

                .ant-select-selection-item {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  text-align: left;
                  color: #514b4d;
                }
              }
            }
          }
        }
      }
    }

    .ant-table-container {
      .ant-table-content {
        border: 1px solid #dcdcdc;
      }

      .ant-table-tbody {
        .ant-table-cell {
          padding: 3.5px 15px;
          height: 52px;
        }

        tr:nth-child(odd) {
          .ant-table-cell {
            background-color: #f7f7f7 !important;

            p {
              color: #514b4d !important;
            }
          }
        }

        tr:nth-child(even) {
          .ant-table-cell {
            background-color: #ffffff !important;

            p {
              color: #514b4d !important;
            }
          }
        }
      }
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
      color: #514b4d;

      span {
        font-size: 12px !important;
      }

      &.status-tracking {
        font-size: 12px;
        line-height: 18px;
      }
    }

    &-sort {
      cursor: pointer;
      user-select: none;
      display: flex;
      gap: 4px;
      align-items: center;
      div {
        font-weight: 600;
      }
      span {
        display: block;
        svg {
          margin-top: -2px;
        }
      }

      .rotate-reverse {
        transform: rotate(180deg);
      }
    }
  }

  &__btn-download {
    position: relative;

    button {
      width: 102px;
      height: 32px;
      border-radius: 4px;
      border: 2px solid #1c86ed;
      gap: unset;

      span {
        font-size: 12px;
        font-weight: 500;
        color: #1c86ed;
        margin-left: -2px;
      }

      svg {
        margin-left: -6px;
      }

      &:hover {
        background-color: #1c86ed !important;
        color: #fff !important;

        span {
          color: #fff;
        }

        svg {
          stroke: #fff;

          path {
            fill: #fff;
          }
        }
      }
    }

    .loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      width: 102px;
      height: 32px;
      opacity: 0.5;
      background-color: #fff;
      cursor: not-allowed;

      button {
        pointer-events: none;
      }
    }
  }
}

.select-option-day {
  .ant-select {
    width: 132px;
    height: 40px;

    &:hover {
      .ant-select-arrow:not(:last-child) {
        opacity: 1;
      }
    }

    .ant-select-arrow {
      right: 0 !important;
    }

    .ant-select-clear {
      display: none;
    }
  }

  span {
    color: #514b4d;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-right: 8px;
  }
}

.tab-check-icon {
  margin-left: 8px;
}

.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #7e7e7e !important;
}

.spin-form-wrap {
  &.ant-spin {
    max-height: unset !important;
  }
}
